import React, { useEffect } from 'react'

// Components
import GlobalContainer from '../globalContainer'
import Grid from '@material-ui/core/Grid'
import Button from '../../global/button'
import Typography from '../../global/typography'

// Styles
import { ResourceTabsContainer } from './style'

// Helpers
import { getMicrocopyData } from '../../../utils/microcopy.utils'
import ImgUtil from '../../../utils/img.utils'
import moment from 'moment'
import { generateBlogPostSlug } from '../../../utils/blog.utils'
import { isResourceCategory } from '../../../utils/functions.utils'

// Constants
import { ModuleNames } from '../../../constants/componentNames.constants'
import WrapperImage from '../wrapperImage'

const ResourceTabs = (props: any) => {
  if (props.data) {
    const tabsId = props.data.id ? props.data.id : ''
    let headline = props.data.headline ? props.data.headline : ''
    let tab1 = props.data.tab1 ? props.data.tab1 : ''
    let tab2 = props.data.tab2 ? props.data.tab2 : ''
    let tab3 = props.data.tab3 ? props.data.tab3 : ''

    let tab1Primary = props.data.tab1Primary ? props.data.tab1Primary : ''
    let tab2Primary = props.data.tab2Primary ? props.data.tab2Primary : ''
    let tab3Primary = props.data.tab3Primary ? props.data.tab3Primary : ''

    let tab1Secondary = props.data.tab1Secondary ? props.data.tab1Secondary : ''
    let tab2Secondary = props.data.tab2Secondary ? props.data.tab2Secondary : ''
    let tab3Secondary = props.data.tab3Secondary ? props.data.tab3Secondary : ''

    let tab1Robot: string = '',
      tab1RobotAlt: string = '',
      tab2Robot: string = '',
      tab2RobotAlt: string = '',
      tab3Robot: string = '',
      tab3RobotAlt: string = ''

    if (props.data.tab1Robot) {
      if (props.data.tab1Robot.altText)
        tab1RobotAlt = props.data.tab1Robot.altText
      if (
        props.data.tab1Robot.image &&
        props.data.tab1Robot.image.file &&
        props.data.tab1Robot.image.file.url
      )
        tab1Robot = props.data.tab1Robot.image.file.url
    }

    if (props.data.tab2Robot) {
      if (props.data.tab2Robot.altText)
        tab2RobotAlt = props.data.tab2Robot.altText
      if (
        props.data.tab2Robot.image &&
        props.data.tab2Robot.image.file &&
        props.data.tab2Robot.image.file.url
      )
        tab2Robot = props.data.tab2Robot.image.file.url
    }

    if (props.data.tab3Robot) {
      if (props.data.tab3Robot.altText)
        tab3RobotAlt = props.data.tab3Robot.altText
      if (
        props.data.tab3Robot.image &&
        props.data.tab3Robot.image.file &&
        props.data.tab3Robot.image.file.url
      )
        tab3Robot = props.data.tab3Robot.image.file.url
    }

    let allTabs = [tab1, tab2, tab3]

    let allTabData = [
      { tab1Primary, tab1Secondary, tab1Robot, tab1RobotAlt },
      { tab2Primary, tab2Secondary, tab2Robot, tab2RobotAlt },
      { tab3Primary, tab3Secondary, tab3Robot, tab3RobotAlt },
    ]

    let allItems: NodeListOf<Element>
    let allTabsElem: NodeListOf<Element>

    const resetAll = () => {
      for (let i = 0; i < allItems.length; i++) {
        allItems[i].classList.remove('active')
        allTabsElem[i].classList.remove('activeTab')
      }
    }
    const onClickHandler = (e: any) => {
      resetAll()
      e.target.classList.add('activeTab')
      allItems[e.target.dataset.idx].classList.add('active')
    }

    const getResourceData = (resource: any) => {
      let category = ''
      let number = -1
      let image = ''
      let imageAlt = ''
      let title = ''

      if (resource.__typename === ModuleNames.PAGE_RESOURCE_DEMO) {
        image = resource?.spotlight?.video?.image?.file?.url
        imageAlt = resource?.spotlight?.video?.altText
        title = resource?.hero?.headline
      } else if (resource.__typename === ModuleNames.BLOG_POST) {
        image = resource.image?.image?.file?.url
        imageAlt = resource.image?.altText
        title = resource.h1Title
      } else {
        image = resource.image?.image?.file?.url
        imageAlt = resource.image?.altText
        title = resource.title
      }

      let date = resource.date ? resource.date : ''
      if (resource.category) {
        if (resource.category.title) category = resource.category.title
        if (resource.category.number) number = resource.category.number
      }

      let microcopy = resource.microcopy ? resource.microcopy : undefined

      let url1 = ''
      if (resource.__typename === ModuleNames.TEASER) {
        if (resource.url1) url1 = resource.url1
      } else if (resource.slug) {
        if (resource.__typename === ModuleNames.BLOG_POST) {
          url1 = generateBlogPostSlug(resource.slug, resource?.category?.name)
        } else
          url1 =
            resource.slug.charAt(0) === '/'
              ? resource.slug
              : `/${resource.slug}`
      }

      let cta: any
      if (microcopy && number != -1)
        cta = getMicrocopyData(microcopy, number, 'primary', 'text')

      let convertedDate
      if (date) {
        convertedDate = new Date(date)
        date = moment(convertedDate).format('MMMM D, YYYY')
      }

      let when, ctaText
      if (
        (isResourceCategory('Webinar', number) ||
          isResourceCategory('Events', number)) &&
        cta &&
        cta.length >= 2 &&
        convertedDate
      ) {
        let now = new Date()
        when = convertedDate >= now ? 'before' : 'after'
        if (cta[0].key && cta[0].key.includes(when)) ctaText = cta[0].value
        else if (cta[1].key && cta[1].key.includes(when)) ctaText = cta[1].value
      }

      // If not webinar, get the first cta text value
      if (!ctaText && cta && cta[0] && cta[0].value) ctaText = cta[0].value

      return { category, number, title, ctaText, url1, image, imageAlt }
    }

    useEffect(() => {
      if (typeof document !== undefined) {
        allTabsElem = document.querySelectorAll(
          `.ResousceTabs__Personas#Resource-Tabs-Outer--${tabsId} > li`
        )
        allItems = document.querySelectorAll(
          `.ResourceTabs__All-Content#Resource-Tabs-Content--${tabsId} > .ResourceTabs__Content-Container`
        )
      }
    }, [])

    return (
      <ResourceTabsContainer data-cy="ResourceTabs">
        <GlobalContainer className="ResourceTabs__Outer-Container">
          <Typography type="header2">{headline}</Typography>
          <ul
            className="ResousceTabs__Personas"
            id={`Resource-Tabs-Outer--${tabsId}`}
          >
            {allTabs.map((val, idx) => {
              return (
                <li
                  data-idx={idx}
                  key={`${tabsId}--tab-${idx}`}
                  onClick={onClickHandler}
                  {...(idx === 0 ? { className: 'activeTab' } : {})}
                >
                  {val}
                </li>
              )
            })}
          </ul>
          <div
            className="ResourceTabs__All-Content"
            id={`Resource-Tabs-Content--${tabsId}`}
          >
            {allTabData.map((tab: any, idx: number) => {
              let prefix = `tab${idx + 1}`

              // primary
              let robot = tab[`${prefix}Robot`] || ''
              let robotAlt = tab[`${prefix}RobotAlt`] || ''
              let primary = tab[`${prefix}Primary`] || ''
              let primaryResourceData = getResourceData(primary)

              // secondary
              let secondary = tab[`${prefix}Secondary`] || []
              let active = idx === 0 ? ' active' : ''
              return (
                <div
                  className={'ResourceTabs__Content-Container' + active}
                  key={idx}
                >
                  {/* Primary Section */}
                  <Grid
                    container
                    spacing={3}
                    className="ResourceTabs__Content--Top"
                  >
                    <Grid item sm={4} md={3}>
                      {primaryResourceData.category && (
                        <Typography type="captionAlt">
                          {primaryResourceData.category}
                        </Typography>
                      )}
                      {primaryResourceData.title && (
                        <Typography type="header3">
                          {primaryResourceData.title}
                        </Typography>
                      )}

                      {primaryResourceData.url1 && (
                        <Button
                          icon={true}
                          href={primaryResourceData.url1}
                          text={primaryResourceData.ctaText}
                          type="text-link"
                          size="small"
                          theme={'dark'}
                        />
                      )}
                    </Grid>
                    {primaryResourceData.image && (
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        className="ResourceTabs__Image-Container"
                      >
                        <WrapperImage
                          src={ImgUtil.getResizedImageUrl(
                            primaryResourceData.image,
                            ''
                          )}
                          alt={primaryResourceData.imageAlt}
                          width={564}
                          height={282}
                          threshold={650}
                        />
                      </Grid>
                    )}

                    {robot && (
                      <Grid
                        item
                        xs={2}
                        className="ResourceTabs__Robot-Container"
                      >
                        <WrapperImage
                          src={ImgUtil.getResizedImageUrl(robot, '')}
                          alt={robotAlt}
                          threshold={650}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {/* Secondary Section */}
                  <Grid
                    container
                    spacing={3}
                    className="ResourceTabs__Content--Bottom"
                  >
                    {secondary.map((tab: any, idx: number) => {
                      let data = getResourceData(tab)
                      return (
                        <Grid item xs={12} sm={3} key={idx}>
                          {data.category && (
                            <Typography type="captionAlt">
                              {data.category}
                            </Typography>
                          )}
                          {data.title && (
                            <Typography type="header6">{data.title}</Typography>
                          )}

                          {data.url1 && (
                            <Button
                              icon={true}
                              href={data.url1}
                              text={data.ctaText}
                              type="text-link"
                              size="small"
                              theme={'dark'}
                            />
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              )
            })}
          </div>
        </GlobalContainer>
      </ResourceTabsContainer>
    )
  } else {
    return null
  }
}

export default ResourceTabs

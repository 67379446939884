import styled from 'styled-components'

export const KeyStatsContainer = styled.div`
  position: relative;
  z-index: 1;

  ${(p) => {
    let paddingStyles = ``
    let greyBg = ``
    if (p.topPadding === 'Large') paddingStyles += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingStyles += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') paddingStyles += 'padding-bottom: 96px;'
    else if (p.bottomPadding === 'Small')
      paddingStyles += 'padding-bottom: 64px;'

    if (p.homePageBg && p.background === 'Grey') {
      greyBg = `background: ${p.theme.webMktPalette.background.gray};`
    } else {
      greyBg = ``
    }

    let themeStyles = ``
    if (p.background === 'Dark') {
      themeStyles += `
      background: ${p.theme.webMktPalette.background.dark};
      h6, p {
        color: rgb(255, 255, 255);
      }
      .KeyStats__Number:before {
        border-bottom: 12px solid ${p.theme.webMktPalette.blue[80]};
      }
      .KeyStats__FinePrint {
        color: rgba(255, 255, 255, 0.3);
      }
    `
    } else if (p.background === 'Grey') {
      themeStyles += `
        h6, p {
          color: rgb(255, 255, 255);
        }
        .KeyStats__Number:before {
          border-bottom: 12px solid ${p.theme.webMktPalette.blue[80]};
        }
        .KeyStats__Inner-Container .KeyStats__Item {
          background: ${p.theme.webMktPalette.background.dark};
        }
      `
    } else {
      themeStyles += `
        h6, p {
          color: rgb(0, 0, 0);
        }
        .KeyStats__Number:before {
          border-bottom: 12px solid ${p.theme.webMktPalette.cyan[20]};
        }
      `
    }

    themeStyles +=
      p.background !== 'Grey' && !p.isCustomerStory
        ? ` .KeyStats__Item:before {
              content: '';
              position: absolute;
              z-index: -1;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-image: radial-gradient(#9c9c9c 1px, transparent 0);
              background-repeat: repeat;
              background-size: 24px 24px;
              background-position: right;
            }`
        : ''

    if (p.background !== 'Dark')
      themeStyles += `
        .KeyStats__FinePrint {
          color: rgba(0, 0, 0, 0.3);
        }
      `

    // DETERMINE STYLES BASED ON PAGE TYPE
    let innerContainerStyles = ``
    if (p.isCustomerStory) {
      innerContainerStyles = `
        padding-bottom: 64px;
        .KeyStats__Inner-Container {
          grid-template-columns: repeat(5, minmax(0, 1fr));
          ${p.theme.breakpoints.down('sm')} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-row-gap: 20px;
          }
          ${p.theme.breakpoints.down('xs')} {
            grid-row-gap: 24px;
            margin-right: auto !important;
            margin-left: 0 !important;
          }
        }
        .KeyStats__Text {
          padding: 0;
          color: ${p.theme.webMktPalette.text.secondary};
        }
        .KeyStats__Item {
          text-align: left;
        }
        .KeyStats__Number {
          margin-top: 6px;
          &:before {
            bottom: 3px;
          }
          ${p.theme.breakpoints.down('md')} {
            &:before {
              bottom: 4px;
            }
          }
        }
        .KeyStats__UpperText {
          margin-top: 16px;
          margin-bottom: 0;
          ${p.theme.breakpoints.down('md')} {
            margin-top: 6px;
          }
        }
        .KeyStats__Text {
          margin-top: 30px;

          ${p.theme.breakpoints.down('sm')} {
            margin-top: 14px;
          }
        }
      `
    } else {
      innerContainerStyles = `
        ${paddingStyles}
        .KeyStats__Inner-Container {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          ${p.theme.breakpoints.down('sm')} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-row-gap: 24px;
          }
        }
        .KeyStats__Text {
          padding: 0 24px;
        }
        .KeyStats__Number {
          margin-top: 64px;
          ${p.theme.breakpoints.down('sm')} {
            margin-top: 48px;
          }
          &:before {
            bottom: 12px;
          }
          ${p.theme.breakpoints.down('md')} {
            &:before {
              bottom: 8px;
            }
          }
        }
        .KeyStats__Item {
          text-align: center;
          height: 272px;

          ${p.theme.breakpoints.down('md')} {
            height: 248px;
          }

          ${p.theme.breakpoints.down('sm')} {
            height: 212px;
          }
        }
        .KeyStats__UpperText {
          margin-top: 62px;
          margin-bottom: 0;

          ${p.theme.breakpoints.down('md')} {
            margin-top: 52px;
          }
        }
        .KeyStats__Text {
          margin-top: 16px;

          ${p.theme.breakpoints.down('sm')} {
            margin-top: 8px;
          }
        }
      `
    }

    return `
      ${themeStyles}
      ${innerContainerStyles}
      ${greyBg}
    `
  }}

  .KeyStats__Inner-Container {
    display: grid;
    grid-column-gap: 24px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 748px;
      margin: 0 auto;
      grid-column-gap: 16px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 328px;
      grid-template-columns: repeat(1, minmax(100%, 1fr));
    }
  }

  .KeyStats__FinePrint {
    max-width: 791px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
    margin: 32px auto 0;
  }

  .KeyStats__Item {
    position: relative;
    .KeyStats__UpperText {
      display: block;

      & + .KeyStats__Number {
        margin-top: 8px;
      }
    }

    .KeyStats__Number {
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        z-index: -1;
      }

      ${(p) => p.theme.breakpoints.down('md')} {
        &:before {
          border-width: 8px;
        }
      }
    }
  }
`

// Libraries
import React from 'react'

// Styles
import { GridOuter, LogoContainer, StyledGrid } from './grid'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import WrapperImage from '../wrapperImage'

type GridProps = {
  data: {
    __typename: string
    name: string
    id: string
    logos: { altText?: string; image?: { file?: { url?: string } } }[]
    topPadding?: string
    bottomPadding?: string
    theme?: string
    isHomePage?: boolean
  }
}

const Grid = (props: GridProps) => {
  if (props.data && props.data.logos) {
    let { logos } = props.data
    let topPadding = props.data.topPadding ? props.data.topPadding : 'None'
    let bottomPadding = props.data.bottomPadding
      ? props.data.bottomPadding
      : 'Large'
    let theme = props.data.theme ? props.data.theme : 'Light'
    let gridItemClasses = ''
    let gridItemImgWidth: number, gridItemImgHeight: number
    if (logos.length === 3) {
      gridItemClasses = 'Grid__Item--LG'
      gridItemImgWidth = 384
      gridItemImgHeight = 192
    } else if (logos.length === 4) {
      gridItemClasses = 'Grid__Item--MD'
      gridItemImgWidth = 282
      gridItemImgHeight = 141
    } else if (logos.length >= 5) {
      gridItemClasses = 'Grid__Item--SM'
      gridItemImgWidth = 180
      gridItemImgHeight = 90
    }
    return (
      <GridOuter
        gridContainerTheme={theme}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        isHomePage={props.isHomePage ? props.isHomePage : undefined}
        data-cy="Grid"
      >
        <StyledGrid gridItems={logos.length}>
          {logos &&
            logos.map((logo: any, i: number) => {
              let alt, url
              if (logo.altText) {
                alt = logo.altText
              }
              if (logo.image && logo.image.file && logo.image.file.url) {
                url = logo.image.file.url
              }
              if (url) {
                return (
                  <LogoContainer className={gridItemClasses} key={i}>
                    <WrapperImage
                      alt={alt}
                      src={ImgUtil.getResizedImageUrl(url, '')}
                      width={gridItemImgWidth}
                      height={gridItemImgHeight}
                      threshold={650}
                    />
                  </LogoContainer>
                )
              }
            })}
        </StyledGrid>
      </GridOuter>
    )
  } else {
    return null
  }
}

export default Grid

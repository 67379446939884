import React from 'react'
import { PlatformDiagramOuter } from './platformDiagram'

// Components
import Typography from '../typography'
import GlobalContainer from '../globalContainer'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import { isExternalURL, createLinkBasedOnType } from '../../../utils/link.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'

// Images
import ArrowRight from '../../../../static/steam-img/arrows/ArrowLong_Right-Grey-30.svg'
import WrapperImage from '../wrapperImage'

type PlatformDiagramProps = any

const PlatformDiagram = (props: PlatformDiagramProps) => {
  if (props.data) {
    const headline = props.data.headline ? props.data.headline : ''
    const itemDescription = props.data.itemDescription
      ? props.data.itemDescription
      : ''

    const iconTextItems = props.data.items ? props.data.items : undefined
    const navGroupItems = props.data.megamenu

    return (
      <GlobalContainer>
        <PlatformDiagramOuter data-cy="PlatformDiagram">
          <div className="PlatformDiagram__Top-Outer">
            <div className="PlatformDiagram__Heading-Container">
              <span className="PlatformDiagram__Heading-Orange-Line"></span>
              <Typography className="PlatformDiagram__Heading" type="header2">
                {formatInlineCode(headline)}
              </Typography>
            </div>
            <div className="PlatformDiagram__Top-Grid"></div>
          </div>
          <div className="PlatformDiagram__Navigation-Group">
            {navGroupItems &&
              navGroupItems.pages &&
              navGroupItems.pages.map((group: any, i: number) => {
                let labelColor = group.groupNameColor
                  ? `--${group.groupNameColor.toLowerCase()}`
                  : '--black'

                return (
                  <div
                    className="PlatformDiagram__Nav-Item"
                    key={`menuGroup--${i}`}
                  >
                    <Typography
                      className={`PlatformDiagram__Nav-Item-Heading Menu-container-label${labelColor}`}
                      type="header6"
                    >
                      {group.newGroupName}
                    </Typography>
                    <Typography
                      className="PlatformDiagram__Nav-Item-Description"
                      type="bodyXS"
                    >
                      {group.menuGroupDescription}
                    </Typography>
                    {group.pages &&
                      group.pages.map((page: any, k: number) => {
                        let target
                        const parentLinkHref = createLinkBasedOnType(page)
                        if (isExternalURL(parentLinkHref)) {
                          target = '_blank'
                        } else {
                          target = '_self'
                        }
                        return (
                          <a
                            href={parentLinkHref}
                            key={`menuGroupPage--${k}`}
                            target={target}
                            className="PlatformDiagram__Nav-Item-Link"
                          >
                            {page.text}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
          </div>
          <div className="PlatformDiagram__Bottom-Items">
            <Typography
              className="PlatformDiagram__Bottom-Heading"
              type="header5"
            >
              {itemDescription}
            </Typography>
            <div className="PlatformDiagram__Bottom-Grid">
              {iconTextItems &&
                iconTextItems.map((iconText: any, n: number) => {
                  const imgSrc =
                    iconText.colorIcon &&
                    iconText.colorIcon.file &&
                    iconText.colorIcon.file.url
                      ? iconText.colorIcon.file.url
                      : ''

                  const imgAlt = iconText.seo ? iconText.seo : 'UiPath Icon'
                  const iconTextHeading = iconText.title ? iconText.title : ''
                  let showArrow = n !== iconTextItems.length - 1
                  return (
                    <React.Fragment key={`iconTextItem--${n}`}>
                      <div className="PlatformDiagram__Icon-Text">
                        <div className="PlatformDiagram__Icon-Text-Image-Container">
                          <WrapperImage
                            className="PlatformDiagram__Icon-Text-Image"
                            src={imgSrc}
                            alt={imgAlt}
                            height={64}
                            width={64}
                            threshold={650}
                          />
                        </div>
                        <Typography
                          className="PlatformDiagram__Icon-Text-Copy"
                          type="label"
                        >
                          {iconTextHeading}
                        </Typography>
                      </div>
                      {!showArrow && (
                        <WrapperImage
                          src={ArrowRight}
                          alt="Arrow Right"
                          className="PlatformDiagram__Icon-Arrow"
                          threshold={650}
                        />
                      )}
                    </React.Fragment>
                  )
                })}
            </div>
          </div>
        </PlatformDiagramOuter>
      </GlobalContainer>
    )
  } else {
    return null
  }
}

export default PlatformDiagram

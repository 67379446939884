import styled from 'styled-components'

export const StyledGrid = styled.div`
  ${({ theme, gridItems }) => {
    return `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1224px;
    margin: 0 auto -24px auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    ${theme.breakpoints.down('md')} {
      max-width: 984px;
    }
    ${theme.breakpoints.down('sm')} {
      max-width: 752px;
      margin: 0 auto -16px auto;
    }
    ${LogoContainer} {
      &.Grid__Item--LG {
        width: 384px;
        height: 192px;
        ${theme.breakpoints.down('md')} {
          width: 304px;
          height 152px;
        }
      }
      &.Grid__Item--MD {
        width: 282px;
        height: 141px;
        ${theme.breakpoints.down('md')} {
          width: 222px;
          height 111px;
        }
      }
      &.Grid__Item--SM {
        width: 180px;
        height: 90px;
        ${theme.breakpoints.down('md')} {
          width: 140px;
          height 70px;
        }
      }
      &.Grid__Item--LG,&.Grid__Item--MD,&.Grid__Item--SM {
        ${theme.breakpoints.down('sm')} {
          width: 172px;
          height: 86px;
        }
        ${theme.breakpoints.down('xs')} {
          width: 156px;
          height: 78px;
        }
      }

      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 0 12px 24px 12px;
      ${theme.breakpoints.down('sm')} {
        margin: 0 8px 24px 8px;
      }
      ${theme.breakpoints.down('xs')} {
        margin: 0 8px 16px 8px;
      }
      img {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  `
  }}
`

export const GridOuter = styled.div`
  ${({ gridContainerTheme, bottomPadding, topPadding, theme, isHomePage }) => {
    let paddingBottom, paddingTop, itemBg
    let largePadding = '96px'
    let smallPadding = '64px'
    let noPadding = '0'
    if (bottomPadding === 'Large') {
      paddingBottom = largePadding
    } else if (bottomPadding === 'None') {
      paddingBottom = noPadding
    } else if (bottomPadding === 'Small') {
      paddingBottom = smallPadding
    }

    if (topPadding === 'Large') {
      paddingTop = largePadding
    } else if (topPadding === 'None') {
      paddingTop = noPadding
    } else if (topPadding === 'Small') {
      paddingTop = smallPadding
    }

    if (isHomePage) {
      itemBg = ``
    } 

    return `
    padding-bottom: ${paddingBottom};
    padding-top: ${paddingTop};
    background: ${
      gridContainerTheme === 'Light'
        ? theme.webMktPalette.background.white
        : theme.webMktPalette.background.gray
    };
    ${theme.breakpoints.down('sm')} {
      padding-bottom: 64px;
    }
    ${StyledGrid} {
      ${itemBg}
    }
  `
  }}
`

export const LogoContainer = styled.div``

import React from 'react'
import { IconsContainer } from './style'
import Grid from '@material-ui/core/Grid'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ImgUtil from '../../../utils/img.utils'
import options from '../../../utils/richText.utils'
import GlobalContainer from '../globalContainer'
import formatInlineCode from '../../../utils/shortTextFormatting'
import Button from '../button'
import Typography from '../typography'
import { internalLinkHandler } from '../../../utils/link.utils'
import WrapperImage from '../wrapperImage'

const Icons = (props: any) => {
  if (props.data) {
    let theme: string = 'Light',
      topPadding: string = 'None',
      bottomPadding: string = 'Large',
      layout: string = 'Text to Right',
      rowMax: string = '2',
      icons: any

    if (props.data.theme) theme = props.data.theme
    if (props.data.icons) icons = props.data.icons
    if (props.data.layout) layout = props.data.layout
    if (props.data.rowMax) rowMax = props.data.rowMax
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding

    let property = {}

    if (rowMax === '2') {
      property = {
        xs: 12,
        sm: 6,
        md: 6,
      }
    } else if (rowMax === '3') {
      property = {
        xs: 12,
        sm: 6,
        md: 4,
      }
    } else if (rowMax === '4') {
      property = {
        xs: 12,
        sm: 6,
        md: 3,
      }
    } else if (rowMax === '5') {
      property = {
        xs: 6,
      }
    } else if (rowMax === '6') {
      property = {
        xs: 6,
        sm: 2,
        md: 2,
      }
    }

    return (
      <IconsContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        background={theme}
        layout={layout}
        columns={rowMax}
        data-cy="Icons"
      >
        <GlobalContainer className="Icons__Outer-Container">
          <Grid container className="Icons__Inner-Container" spacing={3}>
            {icons &&
              icons.map((item: any, index: number) => {
                let icon = ''
                if (
                  theme === 'Dark' &&
                  item.whiteIcon &&
                  item.whiteIcon.file &&
                  item.whiteIcon.file.url
                ) {
                  icon = item.whiteIcon.file.url
                } else if (
                  (theme === 'Light' || theme === 'Grey') &&
                  item.colorIcon &&
                  item.colorIcon.file &&
                  item.colorIcon.file.url
                ) {
                  icon = item.colorIcon.file.url
                }
                return (
                  <Grid
                    item
                    {...property}
                    className="Icons__Item-Container"
                    key={index}
                    data-cy="Icons__Item-Container"
                  >
                    {icon && (
                      <WrapperImage
                        src={ImgUtil.getResizedImageUrl(icon, `width=64`)}
                        alt="icon"
                        threshold={650}
                      />
                    )}
                    <div className="Icons__Text-Container">
                      {item.title && (
                        <Typography className="Icons__Title" type="label">
                          {formatInlineCode(item.title)}
                        </Typography>
                      )}
                      {item.body && (
                        <Typography type="body">
                          {renderRichText(item.body, options)}
                        </Typography>
                      )}
                      {item.iconCta &&
                        item.iconCta.text &&
                        (item.iconCta?.externalUrl ||
                          item.iconCta?.internalLink) && (
                          <Button
                            icon={true}
                            href={
                              item.iconCta.internalLink
                                ? internalLinkHandler(item.iconCta.internalLink)
                                : item.iconCta.externalUrl
                            }
                            text={item.iconCta.text}
                            type="text-link"
                            size="small"
                            className="Icon__CTA"
                            theme={theme === 'Dark' ? 'dark' : 'light'}
                          />
                        )}
                    </div>
                  </Grid>
                )
              })}
          </Grid>
        </GlobalContainer>
      </IconsContainer>
    )
  }
  return null
}

export default Icons
